"use client";

import { createContext, useState, useContext } from "react";

const DataContext = createContext(null);

export const DataProvider = ({ children }) => {
  const [userProfiles, setUserProfiles] = useState(null);
  const [quizDisplayName, setQuizDisplayName] = useState(null);
  const [ownerEmail, setOwnerEmail] = useState(null);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [userRole, setUserRole] = useState(null);

  return (
    <DataContext.Provider value={{ userProfiles, setUserProfiles, quizDisplayName, setQuizDisplayName,ownerEmail, setOwnerEmail, selectedProfile, setSelectedProfile, setUserRole, userRole }}>
      {children}
    </DataContext.Provider>
  );
};

export const useDataContext = () => useContext(DataContext);
